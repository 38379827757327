import { useContext, useEffect } from 'react';
import { QueryErrorResetBoundary } from '@tanstack/react-query';
import { ErrorBoundary } from 'react-error-boundary';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { Footer } from '@/isomorphic/components/layout/footer/footer';
import { Header } from '@/isomorphic/components/layout/header/header';
import { Page } from '@/isomorphic/components/page/page';
import { ErrorBoundaryFallback } from './error-boundary-fallback';
import { OnPageNavigate } from '@/isomorphic/components/page/components/on-page-navigate';
import { MyEconomyDrawer } from '@/isomorphic/components/my-economy-drawer/my-economy-drawer';
import { AppContext } from '@/contexts/app-context';
import { useHeartbeat } from '@/hooks/use-heartbeat';
import { HumanyChatBot } from '../components/page/components/humany-chatbot';

export const App = () => {
    const { user, reCaptchaSiteKey } = useContext(AppContext);
    const { startHeartbeat } = useHeartbeat();

    useEffect(() => {
        if (user?.isAuthenticated) {
            window.addEventListener('click', startHeartbeat);
            window.addEventListener('scroll', startHeartbeat);
            window.addEventListener('keypress', startHeartbeat);
        }
    }, [user?.isAuthenticated]);

    return (
        <QueryErrorResetBoundary>
            {({ reset }) => (
                <div className="r-min-h-screen">
                    <OnPageNavigate />
                    <Header />
                    <ErrorBoundary onReset={reset} FallbackComponent={ErrorBoundaryFallback}>
                        <GoogleReCaptchaProvider reCaptchaKey={reCaptchaSiteKey}>
                            <Page />
                        </GoogleReCaptchaProvider>
                    </ErrorBoundary>
                    <Footer />
                    <MyEconomyDrawer />
                    <HumanyChatBot />
                </div>
            )}
        </QueryErrorResetBoundary>
    );
};
